<template>
  <FormCard
    :title="print.name ? print.name : 'Nova gravação'"
    :status="print.status"
    @cancel="$router.push({ name: 'print' })"
    @save="validate"
  >
    <v-form
      ref="form"
      class="px-5 mt-5"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <SectionTitle title="Dados Básicos" />
          <v-text-field
            v-model="print.name"
            label="Nome"
            placeholder="Nome da gravação"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            dense
            rounded
            outlined
          />
          <v-row>
            <v-col
              v-for="(vendorIdx, index) in vendorShow"
              :key="index"
              class="ml-8"
              cols="12"
              md="11"
            >
              <v-row
                class="py-2"
                align="center"
              >
                <div class="px-4">
                  <v-btn
                    icon
                    @click="removeRowVendor(index)"
                  >
                    <v-icon
                      color="error"
                      small
                    >
                      fas fa-times
                    </v-icon>
                  </v-btn>
                </div>
                <v-autocomplete
                  v-model="print.vendors[index]"
                  outlined
                  label="Fornecedor"
                  :items="vendors"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  item-text="company_name"
                  rounded
                  hide-details
                  dense
                  return-object
                />
              </v-row>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="ml-2 mb-2"
            >
              <v-btn
                icon
                dark
                class="primary"
                @click="addVendor"
              >
                <v-icon small>
                  fas fa-plus
                </v-icon>
              </v-btn>
              <span
                v-if="print.vendors.length == 0"
                outlined
                class="ml-2"
              >
                Adicione um fornecedor a essa gravação
              </span>
            </v-col>
            <v-col
              cols="12"
              md="1"
            />
          </v-row>

          <v-textarea
            v-model="print.description"
            label="Descrição"
            auto-grow
            outlined
            dense
            rounded
            rows="10"
            row-height="15"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <SectionTitle title="Tabela de Preços" />

          <PrintCostTableRow
            v-for="(row, idx) in print.rows"
            :key="idx"
            :row="row"
            :length="print.rows.length"
            @removeRow="removeRow(row)"
          />

          <v-btn
            icon
            dark
            class="primary mt-2"
            @click="addRow"
          >
            <v-icon small>
              fas fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    FormCard: () => import('@/components/FormCard'),
    PrintCostTableRow: () => import('./PrintCostTableRow'),
  },
  props: {
    valid: Boolean,
    print: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vendors: [],
      vendorSelect: {},
      profile: null,
    }
  },
  computed: {
    vendorShow() {
      if (this.print.vendors == null) {
        return this.getVendors()
      } else return this.print.vendors
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
    this.getVendors()
  },
  methods: {
    validate() {
      let isValid = this.$refs.form.validate()
      if (isValid) this.$emit('save')
    },
    addRow() {
      this.print.rows.push({})
    },
    addVendor() {
      this.print.vendors.push(this.vendorSelect)
    },
    removeRow(row) {
      let idx = this.print.rows.indexOf(row)
      this.print.rows.splice(idx, 1)
    },
    removeRowVendor(index) {
      let idx = this.vendorShow.indexOf(index)
      this.vendorShow.splice(idx, 1)
    },
    getVendors() {
      this.$api
        .get('/vendors')
        .then((res) => {
          this.vendors = res.data?.data.map(vendor => {
            return {
              ...vendor,
              company_name: vendor.company_name.trimStart().toUpperCase()            };
          });          
          if (Array.isArray(this.vendors)) {
            this.vendors.sort((a, b) => {
              if (a.company_name < b.company_name) return -1;
              if (a.company_name > b.company_name) return 1;
              return 0;
            });
          }
          console.log(this.vendors)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
